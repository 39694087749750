import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useCheckInet from "../../hook/useCheckInet";

const PageEvent = () => {
  const checkInet = useCheckInet();
  const [event, setEvent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/");
    } else {
      if (checkInet.isOnline) {
        getDataOnline();
      } else {
        getDataOffline();
      }
    }
  }, []);

  const getDataOffline = () => {
    let events = window.localStorage.getItem("LocalEvent");
    events = JSON.parse(events);
    setEvent(events);
  };

  const getDataOnline = () => {
    axios
      .get(CONFIG.URL + "/event/gate", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setEvent(response.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5"
        style={{
          background: `url('https://eventqiu.com/assets/bg_quiz.jpg') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20 w-full">
          <div className="text-white font-bold text-2xl mb-4">LIST GATE</div>
          <div className="mb-10 flex flex-col justify-center items-center space-y-2.5 w-full">
            {event?.map((item, key) => {
              return (
                <a
                  key={key}
                  href={`/home?event=${item.gate}&id_gate=${item.id_gate}`}
                  className={`uppercase min-w-[160px] max-w-sm px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
                >
                  {item.gate}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageEvent;
